import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { ApiResponse } from '@core/models/api-response.model'
import { ApiService } from '@core/services/api.service'
import dayjs from 'dayjs'
import { Observable } from 'rxjs'
import { Journal, JournalDto } from '../models/journal.model'

@Injectable({
    providedIn: 'root',
})
export class JournalApiService extends ApiService<JournalDto, Journal> {
    protected http: HttpClient

    constructor() {
        const http = inject(HttpClient)

        super(http, 'v2/journals')

        this.http = http
    }

    getIsJournalCreated(date: Date): Observable<ApiResponse<boolean>> {
        return this.http.get<ApiResponse<boolean>>(
            `${this.apiUrl}/is-created?date=${dayjs.utc(date).format('YYYY-MM-DD')}`,
        )
    }

    findForDate(date: Date, userId?: string): Observable<ApiResponse<Journal[]>> {
        return this.http.get<ApiResponse<Journal[]>>(
            `${this.apiUrl}/find-for-date?date=${dayjs
                .utc(date)
                .format('YYYY-MM-DD')}&userId=${userId}`,
        )
    }
}
